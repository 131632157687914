<!-- Version: 1.1.0 -->
<template>
  <div>
    <div>
      <!-- null -->
      <b-icon
        v-if="
          ((isTriState && tempValue === null) ||
            (!isTriState && tempValue === false)) &&
          !isPartSelect
        "
        icon="square"
        @click="select(null)"
      />
      <!-- true -->
      <b-icon
        v-if="tempValue === true && !isPartSelect"
        icon="check-square-fill"
        @click="select(true)"
      />
      <!-- half -->
      <b-icon v-if="isPartSelect" icon="dash-square-fill" @click="select()" />
      <!-- false -->
      <b-icon
        v-if="isTriState && tempValue === false && !isPartSelect"
        icon="x-square-fill"
        @click="select(false)"
      />
      {{ label }}
    </div>
    <div class="eh-base-input-desc-error-box">
      <div v-if="description" class="eh-base-input-description">
        {{ description }}
      </div>
      <div v-if="!state && error" class="eh-base-input-error-text">
        {{ error }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Checkbox",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    isTriState: Boolean,
    isPartSelect: Boolean,
    value: Boolean,
    label: String,
    description: String,
    state: Boolean,
    error: String,
  },
  data() {
    return {
      tempValue: this.value,
      tempIsPartSelect: this.isPartSelect,
    };
  },
  watch: {
    value(input) {
      this.tempValue = input;
    },
    tempValue(input) {
      this.$emit("change", input);
    },
    /* tempIsPartSelect(input) {
      this.$emit("update:isPartSelect", input);
    }, */
    isPartSelect(input) {
      /* this.tempIsPartSelect = input; */
      if (input) {
        this.tempValue = false;
      }
    },
  },
  methods: {
    select(fromValue) {
      if (this.isPartSelect) {
        this.tempValue = true;
        this.$emit("update:isPartSelect", false);
      } else {
        if (this.isTriState) {
          if (fromValue === null) {
            this.tempValue = true;
          } else if (fromValue === true) {
            this.tempValue = false;
          } else if (fromValue === false) {
            this.tempValue = null;
          }
        } else {
          if (fromValue) {
            this.tempValue = false;
          } else {
            this.tempValue = true;
          }
        }
      }
      this.$emit("click", this.tempValue);
    },
  },
};
</script>
